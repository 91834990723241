import React from "react";
import { FaMicrophone, FaProjectDiagram } from "react-icons/fa";

const PastEvents: React.FC = () => {
  const pastEvents = [
    {
      id: "1",
      title: "Fine-tuning Meta's Llama 3.1 Model",
      date: "August 1st, 2024",
      theme: "LLM Fine-tuning",
      project: "Fine-tuning Meta's Llama 3.1 model",
      sponsor: {
        name: "Brev",
        website: "https://brev.dev",
        twitter: "https://x.com/brevdev",
        logo: "./sponsors/brevdev_logo.jpeg",
      },
      description:
        "Our inaugural event focused on fine-tuning Meta's Llama 3.1 model. Participants learned about the intricacies of large language models and how to customize them for specific use cases.",
      photos: [
        "/assets/first_hackathon_photos/reid_with_hand_out_and_mic_and_bryce_cheese.jpeg",
        "/assets/first_hackathon_photos/carter_brev_with_mic.jpeg",
        "/assets/first_hackathon_photos/group_at_long_table.jpeg",
        "/assets/first_hackathon_photos/group_photo.jpeg",
      ],
      featuredSpeaker: {
        name: "Carter Abdallah",
        image: "./speakers/carter_brev.jpeg",
        linkedin: "https://www.linkedin.com/in/carter-abdallah-958666140/",
      },
    },
    {
      id: "2",
      title: "Creating AI Voice Personas with Sindarin",
      date: "September 5th, 2024",
      theme: "Voice AI",
      project: "Creating an AI Voice Persona. ",
      sponsor: {
        name: "Sindarin",
        website: "https://www.sindarin.tech/",
        twitter: "https://x.com/SindarinTech",
        logo: "https://pbs.twimg.com/profile_images/1742285356135780352/wwa4Oiu8_400x400.jpg",
      },
      description:
        "Our 2nd event focused on building AI personas with the Sindarin platform. Participants were able to build no-code AI personas using the Sindarin platform or to extend a code-based AI cashier at a fictional burger restaurant.",
      photos: [
        "/assets/sindarin_event/anthony_and_sindarin.png",
        "/assets/sindarin_event/reid_and_bryce_presenting.png",
        "/assets/sindarin_event/bryce_table_closeup.png",
        "/assets/sindarin_event/reids_table.png",
      ],
      featuredSpeaker: {
        name: "Brian Atwood",
        image: "./speakers/brian_sindarin.jpeg",
        linkedin: "https://www.linkedin.com/in/batwood011/",
      },
    },
    {
      id: "7",
      title: "AI Tool Demos & Open Hack Session",
      date: "February 6th, 2025",
      theme: "AI Tools",
      project: "Building AI tools with ToolHouse & Building an AI Agent",
      sponsor: {
        name: "ToolHouse",
        website: "https://toolhouse.ai/",
        twitter: "https://x.com/toolhouseai",
        logo: "https://media.licdn.com/dms/image/v2/D560BAQEfkgZDxVpb9g/company-logo_200_200/company-logo_200_200/0/1730936965034/toolhouseai_logo?e=1749081600&v=beta&t=7A6cYhSmNmOzIep8Xhi5B3a8MpmX0nRO1j6aS47H7qA",
      },
      description:
        "Our 7th event focused on building AI tools with ToolHouse and building an AI Agent.",
      photos: [
        // "/assets/toolhouse_event/reid_with_hand_out_and_mic_and_bryce_cheese.jpeg",
        // "/assets/toolhouse_event/carter_brev_with_mic.jpeg",
        // "/assets/toolhouse_event/group_at_long_table.jpeg",
        // "/assets/toolhouse_event/group_photo.jpeg",
      ],
      featuredSpeaker: {
        name: "Orlando (orlie) Kalossakas",
        image: "./speakers/orlando_toolhouse.jpeg",
        linkedin: "https://www.linkedin.com/in/laserfocus/",
      },
    },
    {
      id: "8",
      title: "AI Tool Demos & Open Hack Session",
      date: "March 20th, 2025",
      theme: "Blockchain & AI",
      project: "Building AI tools on Manifold",
      sponsor: {
        name: "Manifold Labs",
        website: "https://www.manifold.inc/",
        twitter: "https://x.com/manifoldlabs",
        logo: "https://media.licdn.com/dms/image/v2/D560BAQGLNZfdJbKKYw/company-logo_200_200/B56ZXtYm62GcAM-/0/1743444406340/manifoldlabs_logo?e=1749081600&v=beta&t=J0sz6rgEU6kny2W9zajzndQRu7EuGAqwbCtdOoM1fmU",
      },
      description:
        "Our 8th event focused on synergies between blockchain and AI tools.",
      photos: [
        // "/assets/toolhouse_event/reid_with_hand_out_and_mic_and_bryce_cheese.jpeg",
        // "/assets/toolhouse_event/carter_brev_with_mic.jpeg",
        // "/assets/toolhouse_event/group_at_long_table.jpeg",
        // "/assets/toolhouse_event/group_photo.jpeg",
      ],
      featuredSpeaker: {
        name: "Josh Brown",
        image: "./speakers/josh_manifold.jpeg",
        linkedin: "https://www.linkedin.com/in/joshgbrown/",
      },
    },
  ];

  return (
    <section>
      <h2 className="text-3xl font-semibold text-accent-green mb-6">
        $ ls past_events/
      </h2>
      <div className="grid grid-cols-1 gap-6">
        {pastEvents.map((event) => (
          <div
            key={event.id}
            className="bg-secondary-black rounded-lg overflow-hidden shadow-lg border border-accent-green"
          >
            <div className="p-6">
              <h3 className="text-2xl font-bold text-accent-green mb-2">
                {event.title}
              </h3>
              <p className="text-secondary-green mb-4">{event.date}</p>
              <div className="flex items-center text-accent-green mb-2">
                <FaMicrophone className="mr-2" />
                <span className="font-semibold">Theme:</span> {event.theme}
              </div>
              <div className="flex items-center text-accent-green mb-4">
                <FaProjectDiagram className="mr-2" />
                <span className="font-semibold">Project:</span> {event.project}
              </div>
              <div className="bg-primary-black rounded-lg p-4 mb-4">
                <p className="text-accent-green font-semibold mb-2">
                  $ cat sponsor_info.txt
                </p>
                <div className="flex items-center">
                  <img
                    src={event.sponsor.logo}
                    alt={event.sponsor.name}
                    className="w-12 h-12 rounded-full mr-4"
                  />
                  <div>
                    <p className="text-secondary-green">{event.sponsor.name}</p>
                    <a
                      href={event.sponsor.website}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-accent-green hover:text-secondary-green"
                    >
                      {event.sponsor.website}
                    </a>
                  </div>
                </div>
              </div>
              {event.featuredSpeaker && (
                <div className="bg-primary-black rounded-lg p-4 mb-4">
                  <p className="text-accent-green font-semibold mb-2">
                    $ cat featured_speaker.txt
                  </p>
                  <div className="flex items-center">
                    <img
                      src={event.featuredSpeaker.image}
                      alt={event.featuredSpeaker.name}
                      className="w-12 h-12 rounded-full mr-4"
                    />
                    <div>
                      <p className="text-secondary-green">
                        {event.featuredSpeaker.name}
                      </p>
                      <a
                        href={event.featuredSpeaker.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-accent-green hover:text-secondary-green"
                      >
                        LinkedIn Profile
                      </a>
                    </div>
                  </div>
                </div>
              )}
              <p className="text-secondary-green mb-4">{event.description}</p>
              <div className="grid grid-cols-4 gap-2">
                {event.photos.slice(0, 4).map((photo, index) => (
                  <img
                    key={index}
                    src={photo}
                    alt={`Event ${index + 1}`}
                    className="w-full h-48 object-cover rounded-lg"
                  />
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default PastEvents;
