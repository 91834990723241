import React, { useState, useEffect } from "react";
import logo from "../assets/hackai_logo_nobg_trim.png";

const Hero: React.FC = () => {
  const [timeLeft, setTimeLeft] = useState<any>(calculateTimeLeft());

  function calculateTimeLeft() {
    const eventDate = new Date("2025-04-03T18:00:00");
    const currentTime = new Date();
    const difference = eventDate.getTime() - currentTime.getTime();

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className="bg-primary-black text-accent-green min-h-screen flex flex-col justify-center items-center pt-16 md:pt-0 px-4 font-mono">
      <div className="flex flex-col md:flex-row w-full max-w-5xl">
        <div className="flex-1 order-2 md:order-1">
          <h1 className="text-3xl md:text-5xl font-bold text-center md:text-left glow-green-sm mb-4">
            Hack_AI
          </h1>
          <p className="text-lg md:text-xl text-center md:text-left mb-6">
            Join us for Hack AI, a 3-hour meetup packed with coding, learning,
            and collaboration!
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-sm md:text-base mb-6">
            <div className="bg-secondary-black p-4 rounded border border-accent-green">
              <p>
                <strong>Who:</strong> Developers from Austin and beyond
              </p>
            </div>
            <div className="bg-secondary-black p-4 rounded border border-accent-green">
              <p>
                <strong>What:</strong> A code-first meetup to build cool
                AI-related projects and learn new skills
              </p>
            </div>
            <div className="bg-secondary-black p-4 rounded border border-accent-green">
              <p>
                <strong>When:</strong> April 3rd <br /> 6:00pm - 8:00pm
              </p>
            </div>
            <div className="bg-secondary-black p-4 rounded border border-accent-green">
              <p>
                <strong>Where:</strong> The Capital Factory, 8th floor (Captain
                America Room)
              </p>
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col items-center order-1 md:order-2 mb-8 md:mb-0">
          <img
            src={logo}
            alt="HackAI Logo"
            className="w-40 h-40 md:w-60 md:h-60 mb-4"
          />
          <div className="text-center">
            <h2 className="text-2xl md:text-4xl font-bold glow-green-sm mb-4">
              Countdown to Kickoff:
            </h2>
            <div className="flex justify-center space-x-4">
              {Object.keys(timeLeft).map((interval) => (
                <div key={interval} className="text-center">
                  <div className="text-2xl md:text-4xl font-bold">
                    {timeLeft[interval]}
                  </div>
                  <div className="uppercase text-secondary-green text-xs md:text-sm">
                    {interval}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
