import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const FAQ: React.FC = () => {
  const faqs = [
    {
      question: "What is Hack AI?",
      answer:
        "Hack AI is a code-first meetup focused on building innovative AI-related projects, learning new skills, and collaborating with other developers.",
    },
    {
      question: "Who can participate in Hack AI?",
      answer:
        "Hack AI is open to developers of all skill levels from Austin and beyond. Whether you're a seasoned professional or a beginner, you're welcome to join.",
    },
    {
      question: "Where is HackAI taking place?",
      answer:
        "The kickoff for the event will be held at the Capital Factory in the Captain America Room on the 8th floor.",
    },
    {
      question: "What should I bring to the event?",
      answer:
        "Bring your laptop, chargers, any necessary cables, and your creative energy! We will provide Wi-Fi, power outlets, and refreshments.",
    },
    {
      question: "Is there a registration fee?",
      answer:
        "No, Hack AI is free to attend. We want to make it accessible for everyone.",
    },
  ];

  const [openIndices, setOpenIndices] = useState<number[]>([]);

  const toggleFAQ = (index: number) => {
    setOpenIndices((prevIndices) =>
      prevIndices.includes(index)
        ? prevIndices.filter((i) => i !== index)
        : [...prevIndices, index]
    );
  };

  return (
    <div
      id="faq"
      className="bg-primary-black text-accent-green py-10 font-mono"
    >
      <div className="max-w-5xl mx-auto px-4">
        <h2 className="text-2xl md:text-3xl font-bold mb-6 text-center glow-green-sm">
          Frequently_Asked_Questions
        </h2>
        {faqs.map((faq, index) => (
          <div
            key={index}
            className="mb-4 border border-accent-green rounded-lg"
          >
            <h3
              className="text-lg md:text-xl font-semibold cursor-pointer p-3 md:p-4 flex justify-between items-center"
              onClick={() => toggleFAQ(index)}
            >
              <span className="pr-2">{`> ${faq.question}`}</span>
              {openIndices.includes(index) ? (
                <FaChevronUp />
              ) : (
                <FaChevronDown />
              )}
            </h3>
            {openIndices.includes(index) && (
              <p className="mt-2 p-3 md:p-4 bg-secondary-black rounded-b-lg text-sm md:text-base">
                {`$ ${faq.answer}`}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
